import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import "./Footer.scss";

export default function Footer() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const Credits = () => (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Credits</ModalHeader>
      <ModalBody>
        <div className="text-danger mb-4">
          Alireza Kavian © 2017 - 2021 All rights reserved.
        </div>
        <div>
          <div className="h5">Appreciations:</div>
          <div className="d-flex flex-column">
            <a href="https://www.freepik.com/vectors/waves">
              Waves vector created by macrovector - www.freepik.com
            </a>
            <a href="https://www.freepik.com/vectors/background">
              Background vector created by starline - www.freepik.com
            </a>
            <a href="https://www.freepik.com/vectors/school">
              School vector created by macrovector - www.freepik.com
            </a>
            <a href="https://www.freepik.com/vectors/certificate">
              Certificate vector created by macrovector - www.freepik.com
            </a>
            <a href="https://www.freepik.com/vectors/infographic">
              Infographic vector created by vectorjuice - www.freepik.com
            </a>
            <a href="https://www.freepik.com/vectors/hand">
              Hand vector created by vectorjuice - www.freepik.com
            </a>
            <a href="https://www.freepik.com/vectors/technology">
              Technology vector created by vectorpouch - www.freepik.com
            </a>
            <a href="https://www.freepik.com/vectors/abstract">
              Abstract vector created by vectorjuice - www.freepik.com
            </a>
            <a href="https://www.freepik.com/vectors/man">
              Man vector created by upklyak - www.freepik.com
            </a>
            <a href="https://www.freepik.com/vectors/background">
              Background vector created by starline - www.freepik.com
            </a>
            <a href="https://www.freepik.com/vectors/cartoon">
              Cartoon vector created by upklyak - www.freepik.com
            </a>
            <a href="https://www.freepik.com/photos/background">
              Background photo created by wirestock - www.freepik.com
            </a>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
  return (
    <footer id="Footer">
      <div className="background flex-column">
        <div className="h6 text-light">
          <blockquote>
            “ Every breath we take, every step we make, can be filled with peace,
            joy and serenity. ”{" "}
            <span className="text-secondary" style={{textShadow: '1px 1px 3px #2b2b2b'}}> &nbsp; —Thich Nhat Hanh</span>
          </blockquote>
        </div>
        <hr />
        <div>
          <Button color="info" onClick={toggle}>
            Credits
          </Button>
          <Credits />
        </div>
        <div className="my-3" />
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://alireza-kavian.github.io/blog"
            className="btn btn-warning"
          >
            <i className="fab fa-blogger-b" />
            <span className="pl-2">My Blog !</span>
          </a>
        </div>
      </div>
    </footer>
  );
}
