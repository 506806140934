import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";

import "./About.scss";

export default function About() {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch("assets/data/about.json")
      .then((resp) => resp.json())
      .then((res) => {
        if (res) {
          setData(res);
        }
      });
  }, []);
  if (!data) {
    return <div></div>;
  }
  return (
    <div id="About">
      <div className="background">
        <Container fluid>
          <Row>
            <Col md={9} className="top-col">
                <Row className="contact">
                  {Object.keys(data.contact).map((k, idx) => {
                    const { text, icon, link } = data.contact[k];
                    return (
                      <Col key={idx} md={6} className="pb-3">
                        <div className="d-flex align-items-center">
                          <div className="badge-pill badge-secondary rounded-circle">
                            <i className={`${icon} fa-lg`} />
                          </div>
                          <div className="d-inline-block">
                            <a href={link||""} target="_blank" rel="noreferrer">{text}</a>
                            <div>{k}</div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                <hr />
                <div className="description text-dark">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.about.description.join(" "),
                    }}
                  />
                </div>
            </Col>
            <Col md={3} />
          </Row>
        </Container>
      </div>
    </div>
  );
}
