import React, { useEffect, useState } from "react";
import { Chip } from "@material-ui/core";
import industryImg from "../../images/industry-min.png";
import fullstackImg from "../../images/fullstack-min.png";
import databaseImg from "../../images/database-min.png";
import aiImg from "../../images/ai-min.png";
import codeImg from "../../images/code-min.png";
import othersImg from "../../images/others-min.png";
import langsImg from "../../images/langs-min.png";
import ieltsImg from "../../images/ielts-min.png";
import greImg from "../../images/gre-min.png";

import "./Skills.scss";

export default function Background() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("assets/data/skills.json")
      .then((resp) => resp.json())
      .then((res) => {
        if (res) {
          setData(res);
        }
      });
  }, []);
  if (!data) {
    return <div />;
  }
  return (
    <div id="Skills">
      <div className="background">
        <div className="help">
          <div>
            <div className="h-default" />
            <span>Low-Level</span>
            <br />
          </div>
          <div>
            <div className="h-primary" />
            <span>Medium-Level</span>
            <br />
          </div>
          <div>
            <div className="h-secondary" />
            <span>High-Level</span>
            <br />
          </div>
        </div>
        <Rep
          {...{
            img: industryImg,
            title: "Industry Knowledge",
            items: data["Industry Knowledge"],
            inv: true,
          }}
        />
        <Rep
          {...{
            img: fullstackImg,
            title: "Full-Stack Tools",
            items: data["Full-Stack Tools"],
          }}
        />
        <Rep
          {...{
            img: databaseImg,
            title: "Database Tools",
            items: data["Database Tools"],
            inv: true,
          }}
        />
        <Rep
          {...{
            img: aiImg,
            title: "AI/ML Tools",
            items: data["AI/ML Tools"],
          }}
        />
        <Rep
          {...{
            img: codeImg,
            title: "Programming Languages & DevOps",
            items: data["Programming Languages & DevOps"],
            inv: true,
          }}
        />
        <Rep
          {...{
            img: othersImg,
            title: "Others",
            items: data["Others"],
          }}
        />
        <Rep
          {...{
            img: langsImg,
            title: "Language",
            items: data["Language"],
            inv: true,
          }}
        />
        <div className="mb-5 w-100" />
        <div className="exams">
          <div>
            <img src={ieltsImg} alt="" />
            <div className="h5 display-4">IELTS (Academic)</div>
          </div>
          <div>
            <div><b>Total Band Score:</b> {data.ielts.total}</div>
            <div><b>Reading:</b> {data.ielts.reading}</div>
            <div><b>Listening:</b> {data.ielts.listening}</div>
            <div><b>Writing:</b> {data.ielts.writing}</div>
            <div><b>Speaking:</b> {data.ielts.speaking}</div>
          </div>
        </div>
        <div className="exams">
          <div>
            <img src={greImg} alt="" />
            <div className="h5 display-4">GRE (General)</div>
          </div>
          <div>
            <div><b>Total Score:</b> {data.gre.total}</div>
            <div><b>Quant:</b> {data.gre.quant}</div>
            <div><b>Verbal:</b> {data.gre.verbal}</div>
            <div><b>Analytical Writing:</b> {data.gre.writing}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Rep = ({ img, title, items, inv = false }) => {
  return (
    <div className="rep">
      <div className={`top${inv ? " inv" : ""}`}>
        <img src={img} alt="" />
        <div className="h2 display-4">{title}</div>
      </div>
      <div className="items">
        {items.map((item, i) => {
          const { name, amount } = item;
          const amount3 = Math.ceil((amount / 100) * 3);
          let color = "";
          switch (amount3) {
            case 2:
              color = "primary";
              break;
            case 3:
              color = "secondary";
              break;
            default:
              color = "default";
              break;
          }
          return (
            <div key={i} className="item">
              <Chip
                size="medium"
                label={name}
                variant="outlined"
                color={color}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
