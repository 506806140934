import React, { useEffect, useState } from "react";
import { Chip, Divider } from "@material-ui/core";

import "./Projects.scss";

export default function Projects() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("assets/data/projects.json")
      .then((resp) => resp.json())
      .then((res) => {
        if (res) {
          setData(res);
        }
      });
  }, []);
  if (!data) {
    return <div />;
  }
  return (
    <div id="Projects">
      <div className="background">
        <Rep
          {...{
            title: "Years 5 ~ 6",
            date: data["year56"].date,
            items: data["year56"].work,
          }}
        />
        <Rep
          {...{
            title: "College Junior/Senior Years",
            date: data["year34"].date,
            items: data["year34"].work,
          }}
        />
        <Rep
          {...{
            title: "College Freshman/Sophomore Years",
            date: data["year12"].date,
            items: data["year12"].work,
          }}
        />
      </div>
    </div>
  );
}

const Rep = ({ title, date, items }) => {
  return (
    <div className="rep">
      <div className="top">
        <div className="title h4 display-4">{title}</div>
        <div style={{ color: "#8b8b8b" }}>
          <i className="fas fa-clock" />
          <span className="ml-2">{date}</span>
        </div>
      </div>
      <div className="items">
        {items.map((item, idx) => {
          const { star, icon, name, for: forr, desc, url } = item;
          return (
            <>
              <div key={name} className="item">
                <div className={`name text-dark`}>
                  <i className={icon} />
                  <div>{name}</div>
                  {star && (
                    <i className="far fa-star" style={{ color: "#c7af16" }} />
                  )}
                  <div className="mx-2" />
                  {url && (
                    <a href={url} target="_blank" rel="noreferrer">
                      <i className="fas fa-external-link-alt text-dark" />
                    </a>
                  )}
                </div>
                <div style={{ fontSize: "0.9em" }} className="my-2">
                  <i className="text-primary">{forr}</i>
                </div>
                <div>{desc}</div>
              </div>
              {idx === items.length-1 ? <></> :<hr />}
            </>
          );
        })}
      </div>
    </div>
  );
};
