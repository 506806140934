import React, { useEffect, useState } from "react";
import Particles from "react-tsparticles";

import Logo from "../../images/logo.png";
import "./Home.scss";

export default function Home() {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch("assets/data/home.json")
      .then((resp) => resp.json())
      .then((res) => {
        if (res) {
          setData(res);
        }
      });
  }, []);
  if (!data) {
    return <div></div>;
  }
  return (
    <div id="Home">
      <div className="bg mainbg">
          <div className="dim"></div>
          {/* <img style={{width: 100, position: 'absolute'}} src={Logo} /> */}
          <div className="name h1 display-1 text-light">{data.name}</div>
      </div>
      <div className="bg">
        <div className="background">
          <div className="details">
            <img className="prof-avatar" alt="" src={`assets/img/${data.avatar}`} />
            <div className="job h4 display-3"><code className="text-danger">{data.job}</code></div>
            <div className="h4 display-4 text-white">{data.based}</div>
            <div className="h4 display-4 text-primary">{data.age}</div>
            <div className="mt-3"><a href="./assets/cv-alireza-kavian.pdf"><button className="btn btn-outline-info">Download CV</button></a></div>
            <hr />
          </div>
          <div className="thereforiam">
            <h3 className="text-white">
              <div>I code,</div>
              <div>therefore I am.</div>
            </h3>
            <p>
              “For me, web development is more than coding. It's a canvas with a blank page and I am the artist to paint it with colors of coding”.
            </p>
          </div>
          <div className="lastupdate h6 display-6 text-light">
            <i className="far fa-calendar" />
            <span className="pr-2 pl-2">Last Update: </span>
            {data.lastupdate}
          </div>
        </div>
      </div>
    </div>
  );
}
