import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Background from "./components/Background/Background";
import Skills from "./components/Skills/Skills";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer/Footer";

import "@fortawesome/fontawesome-free/js/all";
import "./argon-design-system-react/src/assets/vendor/nucleo/css/nucleo.css";
import "./argon-design-system-react/src/assets/css/argon-design-system-react.min.css";
// import "swiper/css/swiper.min.css";
import 'swiper/css';
import "./App.scss";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <Navbar /> */}
      </header>
      <main className="App-main">
        <section id="section-0"><Home /></section>
        <section id="section-1"><About /></section>
        <section id="section-2"><Background /></section>
        <section id="section-3"><Skills /></section>
        <section id="section-4"><Projects /></section>
        <section id="section-5" style={{marginBottom: 0}}><Footer /></section>
      </main>
    </div>
  );
}

export default App;
