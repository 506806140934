import React, { useEffect, useState } from "react";
import { Container, Card, CardTitle, CardText } from "reactstrap";
import { AppBar, Tabs, Tab, Box, Grow } from "@material-ui/core";
import Swiper from "react-id-swiper";

import "./Background.scss";

export default function Background() {
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    fetch("assets/data/background.json")
      .then((resp) => resp.json())
      .then((res) => {
        if (res) {
          setData(res);
        }
      });
  }, []);
  if (!data) {
    return <div />;
  }
  return (
    <div id="Background">
      <div className="background">
        <Container className="d-flex flex-column">
          <AppBar
            position="static"
            // color="transparent"
            color=""
            style={{ borderRadius: "0 0 5 5" }}
          >
            <Tabs
              centered
              value={activeTab}
              onChange={(_, value) => setActiveTab(value)}
              indicatorColor="secondary"
              textColor="primary"
              variant="fullWidth"
            >
              {Object.keys(data).map((k) => {
                const { icon, title } = data[k];
                return (
                  <Tab
                    key={title}
                    label={
                      <>
                        <i className={`${icon} fa-lg`} />
                        <span className="px-1" />
                        <span className="tab-title">{title}</span>
                      </>
                    }
                  />
                );
              })}
            </Tabs>
          </AppBar>

          <TabPanel value={activeTab} index={0}>
            <div>
              <EDU data={data} appear={activeTab === 0} />
            </div>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <div>
              <EXP data={data} appear={activeTab === 1} />
            </div>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <div>
              <AWARDS data={data} appear={activeTab === 2} />
            </div>
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            <div>
              <CERTS data={data} appear={activeTab === 3} />
            </div>
          </TabPanel>
        </Container>
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tab-panel"
      role="tabpanel"
      hidden={value !== index}
      id={`bg-tabpanel-${index}`}
      aria-labelledby={`bg-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={5}>{children}</Box>}
    </div>
  );
}

const params = {
  spaceBetween: 1,
  slidesPerView: 2,
  freeMode: false,
  mousewheel: false,
  grabCursor: true,
  centeredSlides: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  effect: "coverflow",
  coverflowEffect: {
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  },
  breakpoints: {
    1200: {
      spaceBetween: 1,
      slidesPerView: 2,
    },
    310: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
  },
};

const EDU = ({ data, appear }) => {
  return (
    <div>
      {data[0].items.map((item, idx) => {
        const { name, logo, url, degree, date, gpa, relevants } = item;
        return (
          <div key={idx}>
            <Grow in={appear}>
              <div className="caard">
                <CardTitle className="text-dark h5 font-weight-bold">
                  <div>
                    <i className="fas fa-user-graduate" /> &nbsp;
                    {degree}
                  </div>
                </CardTitle>
                <CardText tag="div" className="more text-dark">
                  <div className="degree text-dark font-weight-bold">
                    <img className="logo" src={logo} alt="" />
                    <a href={url} target="_blank" rel="noreferrer">
                      {name}
                    </a>
                  </div>
                  <div className="date">
                    <i className="far fa-clock" />
                    <span>{date}</span>
                  </div>
                  <div className="gpa">
                    <b>GPA:</b> {gpa}
                  </div>
                  {relevants && (
                    <div className="relevants">
                      <b>Relevant Coursework:</b>&nbsp;
                      {relevants.map((r, i) => (
                        <span key={i} style={{ color: "#2b2b2b" }}>
                          {r[0]}
                          <b className="text-primary">,</b>{" "}
                        </span>
                      ))}
                    </div>
                  )}
                </CardText>
              </div>
            </Grow>
          </div>
        );
      })}
    </div>
  );
};

const EXP = ({ data, appear }) => {
  return (
    <div>
      {data[1].items.map((item, idx) => {
        const { name, logo, url, role, date, location, description } = item;
        return (
          <div key={idx}>
            <Grow in={appear}>
              <div className="caard">
                <CardTitle className="text-dark h5 font-weight-bold">
                  <i className="fas fa-user-tie" /> &nbsp;
                  {role}
                </CardTitle>
                <CardText tag="div" className="more text-dark">
                  <div className="role text-dark font-weight-bold">
                    <img className="logo" src={logo} alt="" />
                    <a href={url} target="_blank" rel="noreferrer">
                      {name}
                    </a>
                  </div>
                  <div className="date">
                    <i className="far fa-clock" />
                    <span>{date}</span>
                  </div>
                  <div className="location">
                    <i className="far fa-compass" />
                    <span>{location}</span>
                  </div>
                  <div className="description">
                    {description.map((d, i) => (
                      <div key={i} className="mb-1">
                        {d}
                      </div>
                    ))}
                  </div>
                </CardText>
              </div>
            </Grow>
          </div>
        );
      })}
    </div>
  );
};

const AWARDS = ({ data, appear }) => {
  const params2 = {
    ...params,
    spaceBetween: 2,
    slidesPerView: 2,
    effect: "",
  };
  return (
    <div>
      {data[2].items.map((item, idx) => {
        const { title, name, logo, url, date, location, description } = item;
        return (
          <div key={idx}>
            <Grow in={appear}>
              <div className="caard">
                <CardTitle className="text-dark h5 font-weight-bold">
                  <i className="fas fa-star" /> &nbsp;
                  {title}
                </CardTitle>
                <CardText tag="div" className="more text-dark">
                  <div className="date">
                    <i className="far fa-clock" />
                    <span>{date}</span>
                  </div>
                  <div className="location">
                    <i className="far fa-compass" />
                    <span>{location}</span>
                  </div>
                  <div className="role text-dark font-weight-bold">
                    <img className="logo" src={logo} alt="" />
                    <a href={url} target="_blank" rel="noreferrer">
                      {name}
                    </a>
                  </div>
                  <div className="description">
                    {description.map((d, i) => (
                      <div key={i} className="mb-1">
                        {d}
                      </div>
                    ))}
                  </div>
                </CardText>
              </div>
            </Grow>
          </div>
        );
      })}
    </div>
  );
};

const CERTS = ({ data, appear }) => {
  const params2 = {
    ...params,
    spaceBetween: 2,
    slidesPerView: 2,
    effect: "",
  };
  return (
    <div>
      {data[3].items.map((item, idx) => {
        const { title, name, logo, url, cert, date, location, description } =
          item;
        return (
          <div key={idx}>
            <Grow in={appear}>
              <div className="caard">
                <CardTitle className="text-dark h5 font-weight-bold">
                  <i className="fas fa-scroll" /> &nbsp;
                  {title}
                </CardTitle>
                <CardText tag="div" className="more text-dark">
                  <div className="date">
                    <i className="far fa-clock" />
                    <span>{date}</span>
                  </div>
                  <div className="location">
                    <i className="far fa-compass" />
                    <span>{location}</span>
                  </div>
                  <div className="role text-dark font-weight-bold">
                    <img className="logo" src={logo} alt="" />
                    <a href={url} target="_blank" rel="noreferrer">
                      {name}
                    </a>
                  </div>
                  <div className="description">
                    {description.map((d, i) => (
                      <div key={i} className="mb-1">
                        {d}
                      </div>
                    ))}
                  </div>
                  <div className="cert mt-5">
                    <a
                      className="btn btn-outline-primary btn-block btn-sm"
                      href={cert}
                      target="_blank"
                      rel="noreferrer"
                    >
                      See Certificate
                    </a>
                  </div>
                </CardText>
              </div>
            </Grow>
          </div>
        );
      })}
    </div>
  );
};
